import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components'

import MainNav from './MainNav'

const Header = (props) => (
  <Container>
    <Wrapper>
      <Link to="/">
        <Logo layout={props.layout}>Pierre Rossignol</Logo>
      </Link>
      <MainNav layout={props.layout} />
    </Wrapper>
  </Container>
)

const Container = styled.header`
  width: 100%;
  height: 80px;
`

const Wrapper = styled.div`
  width: 1070px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  
  a{
    text-decoration: none;
  }

  @media (max-width: 1140px) {
    width: 100%;
    padding: 0 40px;
    max-width: none;
  }

  @media (max-width: 780px) {
    padding: 0 30px;
  }
`

const Logo = styled.h1`
  font-weight: bold;
  font-size: 20px;
  color: ${props => props.layout === 'dark' ? '#FFF': '#000'};
  margin: 0;

  @media (max-width: 460px) {
    font-size: 17px;
  }
`

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
