import React from 'react'
import styled from 'styled-components'
import { Link } from "gatsby"
import { OutboundLink } from 'gatsby-plugin-google-analytics'

export default function MainNav(props) {
  return (
    <NavLinkList layout={props.layout}>
      <Link to="/about">About</Link>
      <OutboundLink href="mailto:pierre.rossignol.pro@gmail.com" target="_blank" rel="noopener noreferrer">Contact</OutboundLink>
    </NavLinkList>
  )
}

const NavLinkList = styled.nav`
  display: flex;
  justify-content: flex-end;

  a{
    font-size: 20px;
    line-height: 30px;
    padding: 0 10px;
    margin: 0 5px;
    color: ${props => props.layout === 'dark' ? '#FFF' : '#000'};

    &:last-child{
      margin-right: 0;
    }

    &:hover{
      text-decoration: underline;
    }
  }

  @media (max-width: 460px) {
    a{
      font-size: 17px;
      line-height: 28px;
      margin: 0;
    }
  }
`